<template>
    <div class="awaiting-pix">
        <section class="awaiting-pix__payment-made pb-4">
            <svg class="my-4" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24" fill="none">
                <path d="M20.9498 14.55L14.5598 20.94C13.1598 22.34 10.8598 22.34 9.44978 20.94L3.05977 14.55C1.65977 13.15 1.65977 10.85 3.05977 9.44001L9.44978 3.05C10.8498 1.65 13.1498 1.65 14.5598 3.05L20.9498 9.44001C22.3498 10.85 22.3498 13.15 20.9498 14.55Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.25 6.25L17.75 17.75" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.75 6.25L6.25 17.75" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h1 class="text-">Pix gerado</h1>
        </section>
        <section class="awaiting-pix__info">
            <div class="awaiting-pix__pix d-flex flex-column gap-2">
                <ol>
                    <li>Abra o app do seu banco e entre na opção Pix.</li>
                    <li>Escolha a opção Pagar / Pix copia e cola.</li>
                    <li>Escaneie o QR code. Se preferir, copie e cole o código.</li>
                    <li>Depois, confirme o pagamento.</li>
                </ol>                
                <img width="250" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg/1200px-Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg.png" />
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    Copiar código PIX
                </button>
            </div>
        </section>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

#app {
    height: 100vh
}

.awaiting-pix {
    background: #198fe3;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: Inter, 'sans-serif';
    gap: 1rem;
    height: 100%;
    h2 {
        font-size: 1rem;
        font-weight: 400;
    }
    &__payment-made {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            color: #fff
        }
    }
    &__customer {
        background: #3a3b3b;
    }
    &__pix {
        img {
            margin: auto;
        }
        button {
            padding: 1rem;
            border: none;
            background: #8170fb;
            color: #fff;
            border-radius: .5rem
        }
    }
    &__info {
        background: #f9fbfa;
        width: 100%; 
        padding: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: 100%;
        padding-top: 2rem;
        h2 {
            color: #3a3b3b
        }
    }
    ol {
        padding-left: 1rem
    }
}

@media screen and (max-width: 550px) {
    .awaiting-pix {
        li {
            font-size: .9rem;
        }
    }
}
</style>