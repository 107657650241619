<template>
    <div class="billet-view">
        <section class="billet-view__payment-made pb-4">
            <svg class="my-4" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 25 24" fill="none">
                <path d="M22.5 10V15C22.5 20 20.5 22 15.5 22H9.5C4.5 22 2.5 20 2.5 15V9C2.5 4 4.5 2 9.5 2H14.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22.5 10H18.5C15.5 10 14.5 9 14.5 6V2L22.5 10Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 13H13.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 17H11.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            <h1 class="text-">Boleto gerado</h1>
        </section>
        <section class="billet-view__info">
            <div class="billet-view__billet d-flex flex-column gap-2">
                <p>O acesso ao produto será disponibilizado por e-mail após a compensação do pagamento do boleto.</p>
                <p>A confirmação do pagamento pode levar até 48 horas.</p>
                <h2>Valor: <strong class="text-primary h5">R$ 10,00</strong></h2>            
                <h2>Data de vencimento: <strong>04/06/2011</strong></h2>            
                <h2>Código do Boleto</h2>            
                <p><strong>46196982800000010001110000000000023437125601</strong></p>
                <img width="250" src="https://barcode.tec-it.com/barcode.ashx?data=46196982800000010001110000000000023437125601&code=Code128&dpi=96&dataseparator=" />
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    Copiar código do boleto
                </button>
            </div>
        </section>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

#app {
    height: 100vh
}

.billet-view {
    background: #198fe3;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: Inter, 'sans-serif';
    gap: 1rem;
    height: 100%;
    h2 {
        font-size: 1rem;
        font-weight: 400;
    }
    p, strong {
        word-break: break-all;
    }
    &__payment-made {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            color: #fff
        }
    }
    &__billet {
        img {
            margin: auto;
            width: 100%;
            max-width: 24rem;
            height: 6rem;
            object-fit: contain;
        }
        button {
            padding: 1rem;
            border: none;
            background: #8170fb;
            color: #fff;
            border-radius: .5rem
        }
    }
    &__info {
        background: #f9fbfa;
        width: 100%; 
        padding: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: 100%;
        padding-top: 2rem;
        h2 {
            color: #3a3b3b
        }
    }
}

@media screen and (max-width: 550px) {
    .billet-view {
        h2, p {
            font-size: .9rem !important;
        }
    }
}
</style>